.mobileMenuButton {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	border-top: 1px solid lightgray;
	color: #740012;
	text-decoration: none;

	&:hover {
		cursor: pointer;
		background-color: #f9f9f9;
	}

	&:last-child {
		border-bottom: 1px solid lightgray;
	}
}