.outlineButton {
	border: 4px solid white;
	background-color: transparent;
	font-size: 0.57em;
	color: white;
	padding: 9px 18px;
	border-radius: 5px;
	
	&:hover {
		cursor: pointer;
	}
}

@media only screen and (max-width: 700px) {
	.outlineButton {
		font-size: 4vw;
	}
}