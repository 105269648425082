@font-face {
	font-family: 'Great Vibes';
	src: 
		local('Great Vibes'),
		url('../../fonts/GreatVibes-Regular.ttf');
}

.flexContainer {
	display: flex;
	justify-content: center;

	.content {
		max-width: 50%;

	.title {
		font-family: 'Great Vibes';
		font-weight: bold;
		font-size: 3em;
		text-align: center;
	}

		p {
			font-size: 1.2vw;
			margin-bottom: 50px;
		}
	}
}

@media only screen and (min-width: 1800px) {
	.flexContainer .content {
		p {
			font-size: 0.9vw;
		}
	}
}

@media only screen and (max-width: 1500px) {
	.flexContainer .content {
		p {
			font-size: 1.3vw;
		}
	}
}

@media only screen and (max-width: 1300px) {
	.flexContainer .content {
		max-width: 75%;

		p {
			font-size: 2.3vw;
		}
	}
}

@media only screen and (max-width: 750px) {
	.flexContainer .content {
		max-width: 100%;
		padding: 0 24px 0 24px;

		p {
			font-size: 4.2vw;
			margin-bottom: 30px;
		}
	}
}