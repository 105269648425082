.mediaContent {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 24px;

	p {
		padding-right: 21px;
	}
}