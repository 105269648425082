.contactContent {
  display: flex;
  padding-top: 21px;

	.serviceTimes {
		display: flex;
		justify-content: right;
		padding-right: 51px;
		border-right: 2px solid lightgray;
	}

	.location {
		display: flex;
		padding: 30px 0 30px 51px;
	}

	.fifty {
		width: 50%;
	}

	.verticalCenter {
		display: flex;
		align-items: center;
	}

	.horizontalAlign {
		text-align: center;

		.lineItem {
			font-size: 1.5em;
		}

		.map {
			width: 450px;
			height: 450px;
		}

		.telephone {
			font-size: 1.5em;
		}
	}
}

.contactTitle {
	font-family: 'Great Vibes';
	font-weight: bold;
	font-size: 3em;
	text-align: center;
	margin: 0;
	padding: 48px 0 48px 0;
}

@media only screen and (max-width: 1100px) {
  .contactContent {
    display: block;

		.serviceTimes {
			display: flex;
			justify-content: center;
			padding: 0 0 52px 0;
			border-right: none;
			border-bottom: 2px solid lightgray;
			width: calc(100% - 144px);
			margin: 0 72px 0 72px;
		}

		.location {
			justify-content: center;
			padding: 52px 0 0 0;
			width: 100%;
		}
  }
}

@media only screen and (max-width: 600px) {
  .serviceTitle {
    font-size: 9vw;
  }

  .horizontalAlign .lineItem {
    font-size: 4.8vw;
    white-space: nowrap;
  }

}

@media only screen and (max-width: 469px) {
	.contactContent {
		.horizontalAlign .map {
			width: 400px;
			height: 400px;
		}
	}
}