header {
	width: 100%;
	height: 100px;
	position: sticky;
	top: 0;
	background: #ffffff;
	display: flex;
	z-index: 1;
	box-shadow: 0 2px 5px #c0c0c0;
}

#headerContent {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px 0 20px;
}

.menuItems {
	display: flex;
}

.menuItem {
	padding: 9px;
}

.menuItem:hover {
	background-color: #e5e5e5;
}