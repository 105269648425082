.heroImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../../../images/IMG_4166-test6.jpg');
  height: 50%;
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroContent {
  color: white;
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
}

.heading {
  padding-bottom: 9px;
  letter-spacing: 0.03em;
}

.subHeading {
  font-size: 0.75em;
  padding-bottom: 39px;
}

@media only screen and (max-width: 780px) {
  .heading {
    font-size: 6.5vw;
  }

  .subHeading {
    font-size: 5vw;
  }
}