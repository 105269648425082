.solidButton {
  	background-color: #740012;
	font-size: 1.1em;
	color: white;
	padding: 12px 18px;
	border-radius: 5px;
	text-decoration: none;
	
	&:hover {
		cursor: pointer;
	}
}

@media only screen and (max-width: 700px) {
	.solidButton {
		font-size: 4vw;
	}
}