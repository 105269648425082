.menuButton {
	color: #940016;
	font-weight: 400;
	font-size: 1.1em;
	text-decoration: none;

	&:not(:first-child) {
		padding-left: 40px;
	}

	&:hover {
		color: #666666;
	}
}