.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #740012;
  color: white;
  height: 150px;

  .separator {
    padding: 0 9px 0 9px;
  }


  a:link, a:visited {
    color: white;
  }

  a:hover {
    color: #666666;
  }
}