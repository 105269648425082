.flexTwoColumn {
  display: flex;

  .leftComponent {
    padding-right: 12px;

    .horizontalAlign {
      text-align: center;
    }
  }

  .rightComponent {
    padding-left: 12px;
  }
}

@media only screen and (min-width: 1500px) {
  .flexTwoColumn {
    .leftComponent {
      width: 180%;
      padding-bottom: 39px;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .leftComponent {
    width: 430%;
  }
}

@media only screen and (max-width: 1100px) {
  .flexTwoColumn {
    display: block;

    .leftComponent {
      width: 100%;
    }
  }

}