.mobileFooter {
  display: flex;
	flex-direction: column;
  justify-content: center;
  background-color: #740012;
  color: white;
  height: 150px;

	.section {
		width: 100%;
		text-align: center;
	}

  .separator {
    padding: 0 9px 0 9px;
  }


  a:link, a:visited {
    color: white;
  }

  a:hover {
    color: #666666;
  }
}