.gospel {
	.verseBlock {
			background-color: #eee5e5;
			border-radius: 6px;
	}

	.verse {
			padding: 0 2vw 0 2vw;
			font-style: italic;
	}

	.verse:first-child {
			padding-top: 15px;
	}

	.verse:last-child {
			padding-bottom: 15px;
	}

	p {
		margin-bottom: 25px !important;
	}
}