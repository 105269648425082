header {
	width: 100%;
	height: 100px;
	position: sticky;
	top: 0;
	background: #ffffff;
	z-index: 1;
	box-shadow: 0 2px 5px #c0c0c0;
}

#headerContent {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px 0 20px;
}

.menu {
	color: #740012;
	font-size: 2rem !important;
}

.menuItem {
	padding: 9px;
}

.menuItem:hover {
	background-color: #e5e5e5;
}

.expandableMenu {
	height: 0;
	z-index: 1;
	width: 100%;
	background-color: #ffffff;
	transition: height 0.3s ease;
	overflow: hidden;
	position: fixed;
}

.expanded {
	height: 366px;
	display: block;
	top: 100px;
}