.pastorImage {
    max-width: 100%;
    height: auto;
}

.ourPastorSection {
    font-family: 'Great Vibes';
    font-weight: bold;
    font-size: 2em !important;
    text-align: center;
    margin-bottom: 21px !important;
}

@media only screen and (min-width: 1100px) {
    .pastorBio {
        margin-top: 0;
    }
}

@media only screen and (max-width: 1100px) {
    .pastorImage {
        max-width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .pastorImage {
        max-width: 80%;
    }
}